// BURGER ICON
function updateMenuButton() {
	$('.burger').find('.burger-icon').toggleClass('is-active');
}

$(document).ready(function() {
	$('.burger').click(function(e){
		e.preventDefault();
		updateMenuButton();

		// $(".nav").toggleClass("show");

		$(".nav").slideToggle(300).toggleClass("show")

		$(this).toggleClass("close");
	});
	$('.mob--search').click(function(e){
		e.preventDefault();
		$('.nav--search').slideToggle(300).toggleClass('show')
	})
});

// ACCORDION NAV
jQuery(document).ready(function(){
	var accordionsMenu = $('.cd-accordion-menu');

	if( accordionsMenu.length > 0 ) {
		accordionsMenu.each(function(){
			var accordion = $(this);
			//detect change in the input[type="checkbox"] value
			accordion.on('change', 'input[type="checkbox"]', function(){
				var checkbox = $(this);
				// console.log(checkbox.prop('checked'));
				( checkbox.prop('checked') ) ? checkbox.siblings('ul').attr('style', 'display:none;').slideDown(300): checkbox.siblings('ul').attr('style', 'display:block;').slideUp(300);
				( checkbox.prop('checked') ) ? checkbox.siblings('label').addClass('active'): checkbox.siblings('label').removeClass('active');
			});
		});
	}
});

$(function() {
    $('#bh-sl-map-container').storeLocator({
        'originMarker': true,
        'originMarkerImg': '/webroot/img/start-pin.png',
        'originMarkerDim': {
            'width': 18,
            'height': 28,
        },
        'bounceMarker': true,
        'storeLimit' : 5,
        'dataType': 'xml',
        'dataLocation' : '/our-products/broker-finder/locations.php',
        'infowindowTemplatePath' : '/our-products/broker-finder/infowindow-description.html',
        'listTemplatePath' : '/our-products/broker-finder/location-list-description.html',
        'mapSettings' : {
            zoom : 9,
        },
        'listColor2': "#ffffff",
        'pagination': true,
        'locationsPerPage': 3,
        'nextPage' : 'Next',
        'prevPage' : 'Prev',
        /*'fullMapStart': true,
        'fullMapStartListLimit' : 3,*/
        /*'dragSearch': true,
        'visibleMarkersList': true*/
    });
});

$(document).ready(function() {

    $('.check-fix').removeAttr('checked');

    $('.question-type').on('click', function() {

        if($(this).parent().hasClass('show')) {
            $(this).parent().removeClass('show');

        } else {
            $('.question-type').parent().removeClass('show');
            $(this).parent().addClass('show');

            // $('.-question').css({
            //     'position': 'absolute',
            //     'z-index': '10'
            // });
        }
    });

    // member profile contact preferences
    if ($('.prod-servs-email-answer').val() == 'yes') {

        $('.prod-servs-email').prop('checked', true);

        $(this).addClass('checked');
    }

    if ($('.prod-servs-post-answer').val() == 'yes') {

        $('.prod-servs-post').prop('checked', true);

        $(this).addClass('checked');
    }

    if ($('.prod-servs-phone-answer').val() == 'yes') {

        $('.prod-servs-phone').prop('checked', true);

        $(this).addClass('checked');
    }

    if ($('.newsletter-email-answer').val() == 'yes') {

        $('.newsletter-email').prop('checked', true);

        $(this).addClass('checked');
    }

    if ($('.newsletter-post-answer').val() == 'yes') {

        $('.newsletter-post').prop('checked', true);

        $(this).addClass('checked');
    }

    if ($('.other-prod-servs-email-answer').val() == 'yes') {

        $('.other-prod-servs-email').prop('checked', true);

        $(this).addClass('checked');
    }

    if ($('.other-prod-servs-post-answer').val() == 'yes') {

        $('.other-prod-servs-post').prop('checked', true);

        $(this).addClass('checked');
    }

    if ($('.other-prod-servs-phone-answer').val() == 'yes') {

        $('.other-prod-servs-phone').prop('checked', true);

        $(this).addClass('checked');
    }

    $('.radio').on('click', function() {

        if ($(this).is(":checked")) {
            $(this).nextAll('.radio-db-fix').first().val('yes');
        } else {
            $(this).nextAll('.radio-db-fix').first().val('no');
        }

    });

    // end

    $('.checked').prop('checked', true);

    if(window.location.href.indexOf("thanks") > -1) {
       $('.form').hide();
       $('.remove-form').hide();
       $('.form-thanks').show();
    }

    // new agent hide shows

    $('.-delegated .radio').on('change', function() {

        $value = $('.-delegated .radio:checked').val();

        if($value == 'Yes') {

            $('.delegated-hide').removeClass('hide');
        } else {
            $('.delegated-hide').addClass('hide');
        }
    })

    $('.-subagents .radio').on('change', function() {

        $value = $('.-subagents .radio:checked').val();

        if($value == 'Yes') {

            $('.subagents-hide').removeClass('hide');
        } else {
            $('.subagents-hide').addClass('hide');
        }
    })

    // contact us forms

    if(window.location.href.indexOf("complaints") > -1) {
        $('.form-regarding').hide();
        $('.contact-question').addClass('hide');

        $('.form-complaints').show();
        $('.contact-policy-complaint').removeClass('hide');

        $('.form-complaints').on('change', function() {

            $dropdownVal = $( ".form-complaints option:selected" ).text();

            $('.contact-form-type').addClass('hide');

            if ($dropdownVal == 'Policy complaint') {

               $('.contact-policy-complaint').removeClass('hide');

            }

            if ($dropdownVal == 'Claim complaint') {

               $('.contact-claim-complaint').removeClass('hide');

            }

            if ($dropdownVal == 'Other complaint') {

               $('.contact-other-complaint').removeClass('hide');

            }
        });
    }

    if(window.location.href.indexOf("question") > -1) {
        $('.form-regarding').hide();
        $('.form-question').show();
    }

    if(window.location.href.indexOf("church") > -1) {
        $('.form-regarding').hide();
        $('.form-church').show();
        $('.contact-question').addClass('hide');
        $('.contact-church-insurance').removeClass('hide');
    }

    if(window.location.href.indexOf("terminology") > -1) {
        $('.form-regarding').hide();
        $('.contact-question').addClass('hide');
        $('.form-terms').show();
        $('.contact-church-terminology').removeClass('hide');
    }

    $('.form-regarding').on('change', function() {
        $dropdownVal = $( ".form-regarding option:selected" ).text();

        $('.contact-form-type').addClass('hide');

        if ( $dropdownVal == 'Submit a question') {

            $('.contact-question').removeClass('hide');

        }

        if ($dropdownVal == 'Church insurance') {

            $('.contact-church-insurance').removeClass('hide');

        }

        if ($dropdownVal == 'Manse insurance') {

            $('.contact-manse-insurance').removeClass('hide');

        }

        if ($dropdownVal == 'Claims') {

            $('.contact-claims').removeClass('hide');

        }

        if ($dropdownVal == 'Policy complaint') {

            $('.contact-policy-complaint').removeClass('hide');

        }

        if ($dropdownVal == 'Claim complaint') {

            $('.contact-claim-complaint').removeClass('hide');

        }

        if ($dropdownVal == 'Other complaint') {

            $('.contact-other-complaint').removeClass('hide');

        }

        if ($dropdownVal == 'Church insurance terminology') {

            $('.contact-church-terminology').removeClass('hide');

        }

        if ($dropdownVal == 'General enquiry') {

            $('.contact-general').removeClass('hide');

        }

				if ($dropdownVal == 'Change contact details') {

            $('.contact-change-contact-details').removeClass('hide');

        }

    });

    // cookies
    // window.cookieconsent_options = {
    //     "message" : "This website uses cookies to ensure you get the best experience on our website",
    //     "dismiss" : "Got it!",
    //     "learnMore" : "More info",
    //     "link" : "../cookie-policy.php",
    //     "theme":"light-bottom"
    // };

    // smooth scroll on ALL anchor links but not on glossary filter disabled links
    $(function() {
        $('.scroll').click(function(e) {
            e.preventDefault;
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });
    });

    // tabs
    $('.tabs').responsiveTabs({
        scrollToAccordion: false
    });

    // tooltips
    $('.tooltip').tooltipster({
        contentAsHTML: true
    });

    // carousel
    $('.js--news').slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1080,
                settings: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    centerMode: true,
                    centerPadding: '150px',
                }
            },
            {
                breakpoint: 780,
                settings: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    centerMode: false
                }
            }
        ]
    });

    $('.news--feed-slick').slick({
      arrows: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: false,
      dots: true,
      appendArrows: '.news--feed-arrows',
      nextArrow: '<button type="button" class="slick-next"></button>',
      prevArrow: '<button type="button" class="slick-prev"></button>',
      appendDots: '.news--feed-dots',
    });

    // accordions
    $('.js--accordion').on('click', function(){
        if($(this).children().hasClass('open')) {
            $(this).children().removeClass('open');
        } else {
            $('.accord-title').removeClass('open');
            $('.accord-content').removeClass('open');
            $(this).children().addClass('open')
        }
    })
		// NEWSLETTER accordion
		$('.ns--accordion .accord-title').on('click', function(){
        if($('.accord-title').hasClass('open')) {
            $('.accord-title').removeClass('open');
						$('.accord-content').removeClass('open');
        } else {
            $('.accord-title').addClass('open');
            $('.accord-content').addClass('open');
        }
    })

    // form accordions


    var articleHeight = 0;
    var heights = [];

    $('.multiple-items li').each( function() {

        var itemH = $(this).outerHeight(true);

        heights.push(itemH);

        articleHeight = Math.max.apply( Math, heights );
    });

    $('.multiple-items li').css('height', articleHeight);

});

// Isotope filtering - from button and url

function getHashFilter() {
    // get filter=filterName
    var matches = location.hash.match( /filter=([^&]+)/i );
    var hashFilter = matches && matches[1];
    return hashFilter && decodeURIComponent( hashFilter );
}

// init Isotope
var $grid = $('.accordions');

// bind filter button click
var $filterButtonGroup = $('.controls');
$filterButtonGroup.on( 'click', 'button', function() {
    var filterAttr = $( this ).attr('data-filter');
    // set filter in hash
    location.hash = 'filter=' + encodeURIComponent( filterAttr );
});

var isIsotopeInit = false;

function onHashchange() {
    var hashFilter = getHashFilter();
    if ( !hashFilter && isIsotopeInit ) {
        return;
    }

    isIsotopeInit = true;

    // filter isotope
    $grid.isotope({
        itemSelector: '.accordion',
        transitionDuration: '0.1s',
        // use filterFns
        filter: hashFilter
    });
    // set selected class on button
    if ( hashFilter ) {
        $filterButtonGroup.find('.is-checked').removeClass('is-checked');
        $filterButtonGroup.find('[data-filter="' + hashFilter + '"]').addClass('is-checked');
    }
}

$(window).on( 'hashchange', onHashchange );

// trigger event handler to init Isotope
onHashchange();

$('.disabled').on('click', function(e) {
    e.preventDefault();
    // console.log('asd');
});

/*$(function () {
    $(".web_address").on("invalid", function () {
        this.setCustomValidity("Include http://");
    });
});*/

var checkboxes = document.getElementsByClassName("-check");

for (var i=0; i<checkboxes.length; i++)  {
  if (checkboxes[i].type == 'checkbox')   {
    checkboxes[i].checked = false;
  }
}
